import { Link } from 'gatsby';
import React from 'react';

import phone from '../images/group-41.png';
import MenuImg from '../images/menuicon.png';
import * as commonStyles from '../styles/head.module.css';

const HDImg = 'https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo.svg';

const PlanHeader = () => {
  return (
    <> <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <nav className="navbar navbar-expand-md  fixed-top" style={{ boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28)', backgroundColor: 'white' }}>
            <a className="navbar-brand" href="#"></a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" style={{ color: 'black' }}></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className={commonStyles.hdContainer + ' navbar-nav'}>
                <Link
                  to="/">
                  <img src={HDImg} className={commonStyles.HDImg} alt="Bubble Life Insurance"/>
                </Link>
              </div>

              <div className="navbar-nav ml-auto" >
                <a href="/company-information/" className={commonStyles.hdTextColor} style={{ padding: '15px 10px' }}>About</a>

                <a href="/homeowners-insurance/" className={commonStyles.hdTextColor} style={{ padding: '15px 10px' }}>Home</a>

                <a href="/life-insurance/" className={commonStyles.hdTextColor} style={{ padding: '15px 10px' }}>Life</a>

                <a><img src={phone} className="phone-img-align" alt="phone"/></a>

                <a><button type="button" className={commonStyles.hdLoginBttn}>Login</button></a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div className={commonStyles.hdMianContainermob}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <nav className="navbar navbar-expand-md  fixed-top" style={{ boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28)', backgroundColor: 'white' }}>
              <a className="navbar-brand" href="#">
                <img src={HDImg} className={commonStyles.HDImg} alt="navbar"/>
              </a>

              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>

                <img src={MenuImg} className={commonStyles.MenuImg} alt="menu"/>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>)
    </>
  );
};

export default PlanHeader;
