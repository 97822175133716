// extracted by mini-css-extract-plugin
export var HDImg = "head-module--HDImg--o-RO8";
export var MenuImg = "head-module--MenuImg--kNBbx";
export var contacttextarea = "head-module--contacttextarea--JrNCQ";
export var contacttextboxes = "head-module--contacttextboxes--ERRbb";
export var hdContainer = "head-module--hdContainer--Beqwp";
export var hdLoginBttn = "head-module--hdLoginBttn--21yPN";
export var hdMianContainermob = "head-module--hdMianContainermob--b8GD7";
export var hdTextColor = "head-module--hdTextColor--RfsiF";
export var navbar = "head-module--navbar--oZwhF";
export var submitbtn = "head-module--submitbtn--XG-6O";
export var textOne = "head-module--textOne--QKshP";