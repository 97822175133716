import '../styles/terms.css';

import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import React from 'react';

import Footer from '../components/footer';
import PlanHeader from '../components/plan-header';
import { getProfile,isAuthenticated, login, logout } from '../utils/auth';

const Home = ( { user } ) => {
  return <p>Hi, {user.name ? user.name : 'friend'}!</p>;
};
const Settings = () => <p>Settings</p>;
const Billing = () => <p>Billing</p>;

export const pageQuery = graphql`
query {
  allStrapiArticle(filter:{title: {eq: "termsofservice"}}) {
    edges {
      node {
        body
        id
        title
      }
    }
  }
}
`;

function createMarkup( html ) {
  return { __html: html };
}


const Account = ( { data } ) => {
  if ( !isAuthenticated() ) {
    login();
    return <p>Redirecting to login...</p>;
  }

  const user = getProfile();
  return (
    <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
      <PlanHeader />

      <div className="container" style={{ marginTop: '146px', marginBottom: '50px' }}>
        <div className="row">
          <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1 className="carrier-plan-heading" style={{ lineHeight: '1.55', width: '65%', textAlign: 'center' }}>
          Accounts Page
            </h1>

            <p>
              <nav>
                <Link to="/account">Home</Link>{' '}

                <Link to="/account/settings">Settings</Link>{' '}

                <Link to="/account/billing">Billing</Link>{' '}

                <a
                  href="#logout"
                  onClick={e => {
                    logout();
                    e.preventDefault();
                  }}
                >
              Log Out
                </a>
              </nav>

              <Router>
                <Home path="/account" user={user} />

                <Settings path="/account/settings" />

                <Billing path="/account/billing" />
              </Router>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Account;
